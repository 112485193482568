import React from "react";
import axios from "axios";

import { Tabs, Tab } from "react-bootstrap";

import ProductTab from "./ProductTab/ProductTab";

import ProductCategoryTab from "./ProductCategoryTab/ProductCategoryTab";

import TopupTab from "../PPOB/Topup/ProductTab";
import HistryTab from "../PPOB/HistorySaldo/ProductTab";

import { useTranslation } from "react-i18next";

export const PpobPage = () => {
  const [tabs, setTabs] = React.useState("product");
  const [refresh, setRefresh] = React.useState(0);
  const [allOutlets, setAllOutlets] = React.useState([]);
  const [allCategories, setAllCategories] = React.useState([]);
  const [allTaxes, setAllTaxes] = React.useState([]);
  const [allUnit, setAllUnit] = React.useState([]);
  const [allMaterials, setAllMaterials] = React.useState([]);
  const [allPpobProduct, setAllPpobProduct] = React.useState([]);
  const [allTopupProduct, setAllTopupProduct] = React.useState([]);
  const API_URL = process.env.REACT_APP_API_URL;
  const userInfo = JSON.parse(localStorage.getItem("user_info"));

  const getOutlet = async () => {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      const outlets = await axios.get(`${API_URL}/api/v1/outlet`);
      setAllOutlets(outlets.data.data);
    } catch (err) {
      setAllOutlets([]);
    }
  };

  const getPpobProduct = async() => {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      const ppobProduct = await axios.get(`${API_URL}/api/v1/kios-product?per_page=999999`);
      setAllPpobProduct(ppobProduct.data.data);
    } catch (err) {
      setAllPpobProduct([]);
    }
  }

  const getKiosCategory = async () => {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      const allCategories = await axios.get(`${API_URL}/api/v1/kios-category?page=1&per_page=999999`);
      setAllCategories(allCategories.data.data);
    } catch (err) {
      setAllCategories([]);
    }
  };

  const getProductCategory = async () => {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      const allCategories = await axios.get(
        `${API_URL}/api/v1/product-category`
      );
      setAllCategories(allCategories.data.data);
    } catch (err) {
      setAllCategories([]);
    }
  };

  const getTopupProduct = async (startDate, endDate) => {
    if (!startDate || !endDate) {
      console.error("Tanggal mulai atau akhir tidak terdefinisi");
      return;
    }

    try {
      const response = await axios.get(`${API_URL}/api/v1/kios-topup`, {
        params: {
          business_id: userInfo.business_id,
          page: 1,
          per_page: 100,
          // type: 'purchase',
          date_start: startDate,
          date_end: endDate
        }
      });
      console.log("Data topup:", response.data.data);
      setAllTopupProduct(response.data.data);
    } catch (err) {
      console.error("Error fetching topup data:", err);
      setAllTopupProduct([]);
    }
  };

  const handleRefresh = () => {
    setRefresh((state) => state + 1);
  };

  React.useEffect(() => {
    getKiosCategory();
    getPpobProduct();
    getProductCategory();
    getTopupProduct();
  }, [refresh]);
  
  const { t } = useTranslation();

  return (
    <>
      <hr />
      <Tabs activeKey={tabs} onSelect={(v) => setTabs(v)}>
        <Tab eventKey="product" title={t("titleTabPPOB")}>
          <ProductTab
            t={t}
            allOutlets={allOutlets}
            allCategories={allCategories}
            allTaxes={allTaxes}
            allUnit={allUnit}
            allMaterials={allMaterials}
            refresh={refresh}
            handleRefresh={handleRefresh}
            userInfo={userInfo}
          />
        </Tab>

        <Tab eventKey="product-category" title={t("titleTabCategory")}>
          <ProductCategoryTab
            t={t}
            allOutlets={allOutlets}
            refresh={refresh}
            allCategories={allCategories}
            handleRefresh={handleRefresh}
          />
        </Tab>

        <Tab eventKey="topup" title={t("topup")}>
          <TopupTab
            t={t}
            allOutlets={allOutlets}
            allCategories={allCategories}
            allTaxes={allTaxes}
            allUnit={allUnit}
            allMaterials={allMaterials}
            allTopupProduct={allTopupProduct}
            refresh={refresh}
            handleRefresh={handleRefresh}
            userInfo={userInfo}
          />
        </Tab>

        <Tab eventKey="history" title={t("history")}>
          <HistryTab
            t={t}
            allOutlets={allOutlets}
            allCategories={allCategories}
            allTaxes={allTaxes}
            allUnit={allUnit}
            allMaterials={allMaterials}
            allTopupProduct={allTopupProduct}
            refresh={refresh}
            handleRefresh={handleRefresh}
            userInfo={userInfo}
          />
        </Tab>
      </Tabs>
    </>
  );
};
