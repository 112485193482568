import React, { useState } from "react";
import axios from "axios";
import {toast} from "react-toastify"
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import {
  Row,
  Col,
  Button,
  Alert,
  Dropdown,
  InputGroup,
  Form,
  Modal
} from "react-bootstrap";
import { Paper } from "@material-ui/core";

import DataTable from "react-data-table-component";
import { Search, MoreHoriz, Delete } from "@material-ui/icons";

import useDebounce from "../../../hooks/useDebounce";

import ConfirmModal from "../../../components/ConfirmModal";
import ProductTopupForm from "./ProductTopupForm";
import EditTopupModal from "./EditTopupModal";
import { useSelector } from "react-redux";

import "../../style.css";

const ProductCategoryTab = ({ refresh, handleRefresh }) => {
  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState("");
  const [alertModal, setAlertModal] = React.useState("");
  const [modalAddToProduct, setModalAddToProduct] = React.useState(false);
  const { t } = useTranslation();
  const [multiSelect, setMultiSelect] = React.useState(false);
  const [clearRows, setClearRows] = React.useState(true);
  const [selectedData, setSelectedData] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState({
    id: "",
    category_name: ""
  });

  const [filter, setFilter] = React.useState({
    category: "",
    products: ""
  });

  const [selectedProducts, setSelectedProducts] = React.useState([]);

  const [allTopupMerchant, setAllTopupMerchant] = React.useState([]);
  const [allProducts, setAllProducts] = React.useState([]);

  const [search, setSearch] = React.useState("");

  const [showConfirm, setShowConfirm] = React.useState(false);
  const [showAddCategory, setShowAddCategory] = React.useState(false);
  const [hiddenCategory, setHiddenCategory] = React.useState("Inactive");

  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const debouncedSearch = useDebounce(search, 1000);

  const closeEditTopupModal = () => setShowEditTopup(false);

  const inputRef = React.useRef();

  const [showEditTopup, setShowEditTopup] = useState(false);
  const [editTopupId, setEditTopupId] = useState(null);

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [topupToDelete, setTopupToDelete] = useState(null);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [totalPages, setTotalPages] = useState(1);

  React.useEffect(() => {
    getProduct();
  }, [refresh]);


  const userInfo = JSON.parse(localStorage.getItem("user_info"));
  console.log("userinfo", userInfo);


  const handleHiddenCategory = (status) => {
    setHiddenCategory(status);
  };

  const formikAddTopup = useFormik({
    initialValues: {
      image: null,
      business_id: userInfo.business_id,
      amount: "",
      top_up_description: ""
    },
    validationSchema: Yup.object({
      image: Yup.mixed().required("Please upload an image"),
      amount: Yup.number().required("Please enter an amount"),
      top_up_description: Yup.string().required("Please enter a description"),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      console.log("values", values);
      const formData = new FormData();
      formData.append('image', values.image);
      formData.append('business_id', userInfo.business_id);
      formData.append('amount', values.amount);
      formData.append('top_up_description', values.top_up_description);

      try {
        
        const response = await axios.post("https://api.beetpos.com/api/v1/kios-topup/create-merchant", formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log("kkkkk", response);
        
        handleRefresh();
        closeAddCategoryModal();
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error(t("errorAddingTopUp"));
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  const formikEditTopup = useFormik({
    initialValues: {
      id: "",
      code: "",
      amount: "",
      image: "",
      top_up_description: ""
    },
    validationSchema: Yup.object().shape({
      amount: Yup.number()
        .required('Amount is required')
        .positive('Amount must be positive'),
      top_up_description: Yup.string()
        .required('Description is required')
        .max(255, 'Description must be at most 255 characters')
    }),
    onSubmit: async (values) => {
      try {
        const API_URL = process.env.REACT_APP_API_URL;
        const formData = new FormData();
        
        formData.append('business_id', userInfo.business_id);
        formData.append('amount', values.amount);
        formData.append('top_up_description', values.top_up_description);
        
        if (values.image && values.image instanceof File) {
          formData.append('image', values.image);
        }

        console.log('Submitting values:', values);
        console.log('FormData entries:', Array.from(formData.entries()));

        const response = await axios.put(
          `${API_URL}/api/v1/kios-topup/update-merchant/${values.id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        console.log('API Response:', response.data);

        if (response.data) {
          toast.success(t('topupUpdatedSuccessfully'));
          handleRefresh();
          closeEditTopupModal();
        } else {
          throw new Error(response.data.message || 'Update failed');
        }
      } catch (error) {
        console.error('Error updating topup:', error);
        toast.error(error.response?.data?.message || t('errorUpdatingTopup'));
      }
    },
    enableReinitialize: true
  });

  const enableLoading = () => setLoading(true);
  const disableLoading = () => setLoading(false);

  const showAddCategoryModal = () => {
    setShowAddCategory(true);
    setTimeout(() => {
      if (inputRef.current) inputRef.current.focus();
    }, 100);
  };
  const closeAddCategoryModal = () => {
    setAlertModal("");
    formikAddTopup.setFieldValue("image", "");
    formikAddTopup.setFieldValue("amount", "");
    formikAddTopup.setFieldValue("description", "");
    setShowAddCategory(false);
  };

  const showEditTopupModal = async (data) => {
    setEditTopupId(data.id);
    try {
      const API_URL = process.env.REACT_APP_API_URL;
      const response = await axios.get(`${API_URL}/api/v1/kios-topup/${data.id}`);
      const topupData = response.data.data;
      
      formikEditTopup.setValues({
        id: topupData.id,
        code: topupData.code,
        image: topupData.image ? `${API_URL}${topupData.image}` : null,
        amount: topupData.amount,
        top_up_description: topupData.top_up_description
      });
      
      setShowEditTopup(true);
    } catch (error) {
      console.error("Error fetching topup data:", error);
      toast.error(t("errorFetchingTopupData"));
    }
  };

  

  // const showConfirmModal = (data) => {
  //   formikEditCategory.setFieldValue("id", data.id);
  //   setShowConfirm(true);
  // };
  const closeConfirmModal = () => setShowConfirm(false);

  const closeAddToProduct = () => {
    setSelectedProducts([]);
    setModalAddToProduct(false);
  };

  const handleMode = () => {
    setSelectedData([]);
    setMultiSelect((state) => !state);
    setClearRows((state) => !state);
  };

  const handleSelected = (state) => setSelectedData(state.selectedRows);
  const handleSelectProducts = (e) => {
    const { value } = e.target;

    const copyProducts = [...selectedProducts];

    if (copyProducts.includes(value)) {
      copyProducts.splice(copyProducts.indexOf(value), 1);
    } else {
      copyProducts.push(value);
    }

    setSelectedProducts(copyProducts);
  };

  const handleAddToProduct = async (
    categories,
    products,
    image,
    amount,
    description,
    e
  ) => {
    e.preventDefault();
    const API_URL = process.env.REACT_APP_API_URL;

    if (!products.length) {
      closeAddToProduct();
    }

    const sendData = {
      image,
      amount,
      description
    };

    try {
      enableLoading();
      await axios.post(
        `${API_URL}/api/v1/kios-topup/create-merchant`,
        sendData
      );
      disableLoading();
      handleRefresh();
      closeAddToProduct();
    } catch (err) {
      console.log(err);
    }
  };

  const [filteredProducts, setFilteredProducts] = React.useState([]);
  const authToken = useSelector((state) => state.auth.authToken);
 
  const getAllTopup = async (search) => {
    try {
      const currentDate = new Date();
      const defaultEndDate = currentDate.toISOString().split('T')[0];
      const defaultStartDate = new Date(currentDate.setMonth(currentDate.getMonth() - 1))
        .toISOString()
        .split('T')[0];

      const response = await axios.get(
        `https://api.beetpos.com/api/v1/kios-saldo-history/merchant`,
        {
          params: {
            business_id: userInfo.business_id || 1,
            page: 1,
            per_page: 10,
            type: 'purchase',
            date_start: startDate || defaultStartDate,
            date_end: endDate || defaultEndDate
          }
        }
      );

      console.log("mmmmmmmm", response);
      

      if (response.data.statusCode === 200) {
        let filteredTopups = response.data.data;
        
        if (search) {
          filteredTopups = filteredTopups.filter((topup) =>
            topup.Kios_Topup_Saldo?.code?.toLowerCase().includes(search.toLowerCase()) ||
            topup.description?.toLowerCase().includes(search.toLowerCase())
          );
        }

        setFilteredProducts(filteredTopups);
        setAllTopupMerchant(filteredTopups);
        
        if (response.data.pagination) {
          setTotalPages(response.data.pagination.total_page);
        }
      }
    } catch (err) {
      console.error("Error fetching topup data:", err);
      setAllTopupMerchant([]);
      setFilteredProducts([]);
      // toast.error(t("errorFetchingData"));
    }
  };

  // Tambahkan useEffect untuk memanggil getAllTopup saat tanggal berubah
  React.useEffect(() => {
    getAllTopup(search);
  }, [startDate, endDate, search]);

  // get all category
  const getProduct = async (search) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const filterProductCategory = search ? `?name=${search}` : "";
    try {
      const filterProduct = await axios.get(
        `${API_URL}/api/v1/kios-category${filterProductCategory}`
      );
      console.log("dwefef", filterProduct);
      setAllProducts(filterProduct.data.data);
    } catch (err) {
      setAllProducts([]);
    }
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowImageModal(true);
  };

  const categoryData = (data) => {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return [];
    }

    const API_URL = process.env.REACT_APP_API_URL;
    console.log("Data from API:", data);

    return data.map((item, index) => ({
      id: item.top_up_id,
      no: index + 1,
      code: item.Kios_Topup_Saldo?.code || '-',
      amount: item.Kios_Topup_Saldo?.amount?.toLocaleString() || '0',
      merchant_cost: item.merchant_cost || 0,
      image: item.Kios_Topup_Saldo?.image ? (
        <img 
          src={`${API_URL}${item.Kios_Topup_Saldo.image}`}
          alt={item.Kios_Topup_Saldo?.code || 'Topup Image'}
          style={{ 
            width: '50px',
            height: '50px',
            objectFit: 'cover',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
          onClick={() => handleImageClick(`${API_URL}${item.Kios_Topup_Saldo.image}`)}
        />
      ) : '-',
      adminDesc: item.Business?.name || '-',
      status: item.status || 'Inactive',
      description: item.description || '-'
    }));
  };

  // Helper function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Helper function to format time
  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const customStyles = {
    headRow: {
      style: {
        minHeight: "78px"
      }
    },
    rows: {
      style: {
        minHeight: "70px"
      }
    }
  };

  const columns = [
    {
      name: `Code`,
      selector: "code",
      sortable: true
    },
    {
      name: `${t("image")}`,
      selector: "image",
      sortable: true
    },
    {
      name: `${t("amount")}`,
      selector: "amount",
      sortable: true,
      width: "140px",
    },
    {
      name: `${t("merchantCost")}`,
      selector: "merchant_cost",
      sortable: true,
      cell: (row) => `Rp ${row.merchant_cost?.toLocaleString() || '0'}`
    },
    {
      name: `${t("description")}`,
      selector: "description",
      sortable: true
    },
    {
      name: `${t("admin")}`,
      selector: "adminDesc",
      sortable: true
    },
    {
      name: `${t("status")}`,
      selector: "status",
      sortable: true
    }
  ];

  const handleDelete = async () => {
    const categoryId = formikEditTopup.getFieldProps("id").value;
    const API_URL = process.env.REACT_APP_API_URL;

    try {
      enableLoading();
      await axios.delete(`${API_URL}/api/v1/kios-topup/cancel/${categoryId}`);

      setAllTopupMerchant(allTopupMerchant.filter((item) => item.id !== categoryId));
      handleRefresh();

      disableLoading();
      closeConfirmModal();
    } catch (err) {
      console.log(err);
    }
  };

  const handleFilter = (e) => {
    const { name, value } = e.target;
    const filterData = { ...filter };
    filterData[name] = value;
    setFilter(filterData);
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    console.log(`[handleSearch] Nilai pencarian diubah: "${searchValue}"`);
    setSearch(searchValue);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDeleteTopup = (id, code) => {
    setTopupToDelete({ id, code });
    setShowDeleteConfirm(true);
  };

  const confirmDelete = async () => {
    if (!topupToDelete) return;

    const API_URL = process.env.REACT_APP_API_URL;
    try {
      enableLoading();
      await axios.delete(`${API_URL}/api/v1/kios-topup/cancel/${topupToDelete.id}`);
      disableLoading();
      toast.success(t("topupDeletedSuccessfully"));
      handleRefresh();
    } catch (error) {
      console.error("Error deleting topup:", error);
      disableLoading();
      toast.error(t("errorDeletingTopup"));
    } finally {
      setShowDeleteConfirm(false);
      setTopupToDelete(null);
    }
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  return (
    <Row>
      <ConfirmModal
        title={t("deleteProductCategory")}
        body={t("areYouSureWantToDelete?")}
        buttonColor="danger"
        handleClick={handleDelete}
        state={showConfirm}
        closeModal={closeConfirmModal}
        loading={loading}
      />

      {/* <ModalAddToProduct
        t={t}
        state={modalAddToProduct}
        closeModal={closeAddToProduct}
        loading={loading}
        alert={alertModal}
        title={`${t("add")} "${selectedCategory.category_name}" ${t(
          "toProducts"
        )}`}
        selectedCategory={selectedCategory}
        selectedProducts={selectedProducts}
        allProducts={allProducts}
        handleSelectProducts={handleSelectProducts}
        handleAddToProduct={handleAddToProduct}
      /> */}

      <ProductTopupForm
        t={t}
        hiddenCategory={hiddenCategory}
        handleHiddenCategory={handleHiddenCategory}
        state={showAddCategory}
        closeModal={closeAddCategoryModal}
        loading={loading}
        alert={alertModal}
        title={t("addProductCategory")}
        formikAddTopup={formikAddTopup}
        inputRef={inputRef}
        isSubmitting={formikAddTopup.isSubmitting}
      />

      <EditTopupModal
        show={showEditTopup}
        onHide={() => setShowEditTopup(false)}
        formik={formikEditTopup}
        loading={loading}
        alert={alertModal}
      />

      <ConfirmModal
        title={t("deleteTopup")}
        body={t("deletingTopupConfirmation", { code: topupToDelete?.code })}
        buttonColor="danger"
        handleClick={confirmDelete}
        state={showDeleteConfirm}
        closeModal={() => setShowDeleteConfirm(false)}
        loading={loading}
      />

      <Modal
        show={showImageModal}
        onHide={() => setShowImageModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("imagePreview")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <img
            src={selectedImage}
            alt="Preview"
            style={{
              maxWidth: '100%',
              maxHeight: '80vh',
              objectFit: 'contain'
            }}
          />
        </Modal.Body>
      </Modal>

      <Col md={12} style={{ minHeight: "100%" }}>
        {alert ? <Alert variant="danger">{alert}</Alert> : ""}

        <Paper elevation={2} style={{ padding: "1rem", height: "100%" }}>
          <div className="headerPage">
            <div className="headerStart">
              {!selectedData.length ? (
                <h3>{t("topup")}</h3>
              ) : (
                <h3>
                  {selectedData.length}
                  {t("itemSelected")}
                </h3>
              )}
            </div>
            <div className="headerEnd">
              {!multiSelect ? (
                <Button variant="primary" onClick={showAddCategoryModal}>
                  {t("topup")}
                </Button>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="filterSection" style={{ maxWidth: "100%" }}>
            <Row className="align-items-center">
              <Col md={4}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text style={{ background: "transparent" }}>
                      <Search />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    placeholder={t("search")}
                    value={search}
                    onChange={handleSearch}
                  />
                </InputGroup>
              </Col>

              <Col md={4}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text style={{ background: "transparent" }}>
                      <Search />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    placeholder={t("startDate")}
                    type="date"
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                </InputGroup>
              </Col>

              <Col md={4}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text style={{ background: "transparent" }}>
                      <Search />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    placeholder={t("endDate")}
                    type="date"
                    value={endDate}
                    onChange={handleEndDateChange}
                  />
                </InputGroup>
              </Col>
            </Row>
          </div>

          <DataTable
            noHeader
            pagination
            columns={columns}
            data={categoryData(filteredProducts)}
            style={{ minHeight: "100%" }}
            selectableRows={multiSelect}
            onSelectedRowsChange={handleSelected}
            clearSelectedRows={clearRows}
            noDataComponent={t("thereAreNoRecordsToDisplay")}
            customStyles={customStyles}
          />
        </Paper>
      </Col>
    </Row>
  );
};

export default ProductCategoryTab;
